import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const AboutTwo = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/weonesfeos.jpeg" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h4 className="subtitle"><span className="theme-gradient">Tech Stacks.</span></h4>
                                    <h2 className="title mt--10">About Our Tech Consultancy.</h2>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    
                                    <ul className="list-icon">
                                        <p><span className="icon"><FiCheck /></span>Utilizing a wide array of tech tools like Python and related libraries, Power BI, Matlab, Weka, Tableau, and both SQL and noSQL databases, we turn complex data into insightful strategies for our clients. This enables them to make better decisions and excel in their operations as well as harness the raw power of AI.</p>
                                        <p><span className="icon"><FiCheck /></span>Our DevSecOps and SysOps teams excel with technolgies that include Linux, Bash, Git, Docker, and Kubernetes, ensuring secure, efficient application management and deployment. With these tools, we keep systems running smoothly and securely, providing top-notch service to our clients.</p>
                                        <p><span className="icon"><FiCheck /></span>Leveraging a mix of Azure, AWS, GCP, and languages including Python, Ruby, JavaScript, TypeScript, C/C++, and Rust, our full-stack development team delivers cutting-edge solutions. We're the preferred partner for clients looking for comprehensive, high-quality applications.</p>
                                        <p><span className="icon"><FiCheck /></span>We utilize Figma and Generative AI in web development, offering fast, efficient, and creative solutions. Our approach ensures visually appealing, superior-functioning websites, making us the top choice for clients.</p>
                                    </ul>

                                   
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="#">More About Us <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutTwo;
