import React from 'react';

const BrandList = [
    {
        image: './images/brand/aws-logo.png'
    },
    {
        image: './images/brand/azure-logo.png'
    },
    {
        image: './images/brand/C-logo.png'
    },
    {
        image: './images/brand/chatGPT-logo.jpg'
    },
    {
        image: './images/brand/Docker-Logo.png'
    },
    {
        image: './images/brand/gcp-logo.png'
    },
    {
        image: './images/brand/javascript-logo.png'
    },
    {
        image: './images/brand/kubernetes-logo.png'
    },
    {
        image: './images/brand/linux-logo.png'
    },
    {
        image: './images/brand/python-logo.jpg'
    },

]

const BrandOne = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href="#"><img src={`${data.image}`} alt="Brand Image" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandOne;
