import React from 'react'
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceFive from "../elements/service/ServiceFive";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamOne from "../elements/team/TeamOne";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import HeaderTopBar from '../common/header/HeaderTopBar';




const AboutUs = () => {
    return (
        <>
            <SEO title="About Us" />
            <main className="page-wrapper">
                <HeaderTopBar />                
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/fotomuchopie.webp)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    
                                    <h1 className="title display-one">We are a Corporate <br /> Consulting Agency.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* weird description area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Visionary Partnerships for Sustainable Growth.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">Founded in 2014 by tech enthusiasts and financial wizards, Green Valley Consulting merges innovation with strategic insight to elevate businesses. Our tailored solutions blend cutting-edge technology with financial prowess, driving growth and efficiency. With a team of experts, we offer personalized services across industries, transforming challenges into opportunities. Committed to community and sustainable practices, we're not just consultants—we're partners in your success story. </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* weird description area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Services provide for you."
                                    description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                    />
                            </div>
                        </div>
                        <ServiceFive
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                

                <Separator />

                <AboutFour image="./images/about/valle_elqui.png" />
                


                <Separator />

                {/* Start Elements Area  */}
                                   {/* Start Elements Area  */}
                                   <div className="rwt-team-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Our Experts."
                                            title = "Our Business Team."
                                            description = ""
                                        />
                                </div>
                            </div>
                            <TeamOne column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="team-style-default" />
                        </div>
                    </div>
                    {/* End Elements Area  */}
                {/* End Elements Area  */}






                <FooterTwo />
            </main>
        </>
    )
}

export default AboutUs;
