import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                   Financial Services Overview
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>Green Valley is a comprehensive consultancy firm specializing in financial, business, and technology sectors. Our expertise spans across various domains, offering tailored solutions to meet the unique needs of our clients. Here's a brief overview of our core financial services. </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Portfolio Management
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>At Green Valley, we understand that managing investments requires a keen eye for detail and a deep understanding of market dynamics. Our portfolio management services are designed to optimize returns while minimizing risks. We employ a mix of strategic asset allocation, diversification, and continuous monitoring to ensure our clients' portfolios remain robust against market fluctuations. Our team of experts works closely with clients to align investment strategies with their financial goals, whether they aim for aggressive growth or conservative stability. We offer personalized advice on selecting assets across equities, bonds, real estate, and alternative investments, ensuring a balanced portfolio that reflects each client's risk tolerance and objectives.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    Mergers and Acquisitions
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>Navigating the complexities of mergers and acquisitions requires precision and foresight. Green Valley provides end-to-end support for businesses looking to expand through strategic partnerships or acquisitions. From initial due diligence to negotiation and post-acquisition integration, we guide clients through every step of the process. Our services include identifying potential targets, valuation, structuring deals, and facilitating smooth transitions. We leverage our extensive network and industry insights to unlock value for both acquiring entities and sellers, ensuring transactions are mutually beneficial and aligned with long-term business strategies.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    Fractional CFO
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>For growing businesses that may not need a full-time Chief Financial Officer but still require high-level financial oversight, Green Valley offers Fractional CFO services. This flexible solution provides access to experienced financial leadership without the commitment of a full-time hire. Our Fractional CFOs bring expertise in financial planning, cash flow management, and strategic decision-making, offering scalable support tailored to the company's size and needs. They work closely with executive teams to implement financial systems, improve profitability, and prepare for future growth, acting as a strategic partner in achieving financial milestones.</Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionOne

