import React from 'react';
import {Link} from "react-router-dom";
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterTwo from '../common/footer/FooterTwo';


import CalltoActionSix from "../elements/calltoaction/CalltoActionSix";
import ServiceThree from "../elements/service/ServiceThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import AboutThree from "../elements/about/AboutThree";
import SlipThree from "../elements/split/SlipThree";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';

import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';

var BlogListData = BlogClassicData.slice(0, 3);



const Finance = () => {
    return (
        <>
            <SEO title="Finance Services" />
            <main className="page-wrapper">
                <HeaderTopBar />                
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider area  */}
                <div className="slider-area slider-style-5 bg-overlay-solid height-850 bg_image" data-black-overlay="3" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/toromiami.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-start">
                                    <h4 className="subtitle">Outsource your <br/> Financial Needs</h4>
                                    <h1 className="title display-one">Financial <br />Services.</h1>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider area  */}


         
                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What Our Experts Can Do For You."
                                    title = "Financial Services."
                                    description = "Expert guidance to navigate market fluctuations,</br> ensuring long-term stability and growth."
                                    />
                            </div>
                        </div>
                        <ServiceThree 
                            serviceStyle = "service__style--2"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />
                <AboutThree />         

                <Separator />
               

                <FooterTwo />
            </main>
        </>
    )
}

export default Finance;
