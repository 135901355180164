import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import PricingOne from "./PricingOne";
import PricingTwo from "./PricingTwo";
import PricingThree from "./PricingThree";
import PricingFour from "./PricingFour";
import PricingFive from "./PricingFive";
import Separator from "../separator/Separator";
import AboutFive from '../about/AboutFive';


//   /home/th4n4/Projects/greenvalley_site/doob/src/elements/pricing/Pricing.js
//   /home/th4n4/Projects/greenvalley_site/doob/src/elements/about/AboutFive.js


const Pricing = () => {
    return (
        <>
            <SEO title="Pricing" />
            <Layout>
                <BreadcrumbOne 
                    title="Empower Your Success: Tailored Pricing Plans."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Pricing"
                />

                <div className="main-content">
                    <Separator/>

                <AboutFive />


                

                    
                </div>
            </Layout>
        </>
    )
}
export default Pricing;