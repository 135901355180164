import React from 'react';
import Typed from 'react-typed';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AboutOne from '../elements/about/AboutOne';
import ServiceFive from '../elements/service/ServiceFive';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import TeamTwo from '../elements/team/TeamTwo';
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';

import CalltoActionFour from '../elements/calltoaction/CalltoActionFour';

import TabTwo from '../elements/tab/TabTwo'

import AboutSix from '../elements/about/AboutSix';



var BlogListData = BlogClassicData.slice(0, 3);




const BusinessConsulting2 = () => {
    return (
        <>
            <SEO title="Home" />
            <main className="page-wrapper">

                <HeaderTopBar />                
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-850">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <span className="subtitle">Green Valley Consulting</span>
                                    <h1 className="title theme-gradient display-two">Unique Consultancy for <br /> {" "}
                                        <Typed
                                            strings={[
                                                "Business.",
                                                "Finance.",
                                                "Technology.",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <p className="description">We help our clients succeed on all dimensions and all spaces.</p>
                                    
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                
                
                {/* Start About Area  */}
                <AboutOne />                            
                {/* End About Area  */}

                <Separator />                            
                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Tailoring Solutions for Your Unique Challenges"
                                    title = "Empowering Success Across Tech, Business, and Finance."
                                    description = "Empowering growth through innovative tech,</br> strategic business insights, and sound financial planning."
                                    />
                            </div>
                        </div>
                        <ServiceFive 
                            serviceStyle = "gallery-style"
                            textAlign = "text-start"
                        />
                    </div>
                </div>
                {/* End Service Area  */}



                <Separator />                

                <CalltoActionFour />



                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default BusinessConsulting2;