import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: '/images/service/bipalquelee.png',
        title: 'Business Services',
        description: 'Driving business success through strategic consulting and innovative solutions with expert guidance.'
    },
    {
        image: '/images/service/stonks.jpg',
        title: 'Financial Services',
        description: 'Navigating financial landscapes with expert guidance for optimized portfolios and secure futures.'
    },
    {
        image: '/images/service/processor1.png',
        title: 'Tech Services',
        description: "Harnessing technology to drive business success with cutting-edge solutions for today's digital challenges."
    }
];

const ServiceFive = ({textAlign, serviceStyle}) => {
    const getServicePath = (serviceName) => {
        switch(serviceName) {
            case 'Buisness Services':
                return '/business';
            case 'Financial Services':
                return '/finance';
            case 'Tech Services':
                return '/digital-agency';
            default:
                return '/';
        }
    };

    return (
        <div className="row row--15 service-wrapper">
            {ServiceList.map((val, i) => (
                <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
                    <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="content">
                                    <h4 className="title">
                                        <Link to={getServicePath(val.title)} dangerouslySetInnerHTML={{__html: val.title}}></Link>
                                    </h4>
                                    <p className="description" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                                <div className="image">
                                    <img src={`${val.image}`} alt="card Images" />
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default ServiceFive;