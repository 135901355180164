import React from 'react'
import SEO from "../common/SEO";
import {Link} from "react-router-dom";


import Slider from "react-slick";
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import ServiceTwo from '../elements/service/ServiceTwo';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import CircleProgress from "../elements/progressbar/CircleProgress";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';


import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';

//import AboutOne from '../elements/about/AboutOne';
import AboutTwo from '../elements/about/AboutTwo';

//import AboutThree from '../elements/about/AboutThree';
//import AboutFour from '../elements/about/AboutFour';
//import AboutFive from '../elements/about/AboutFive';
//import AboutSix from '../elements/about/AboutSix';


import BrandOne from '../elements/brand/BrandOne';


var BlogListData = BlogClassicData.slice(0, 3);



const BannerData = [
    {
        image: "/images/bg/wea5.jpg",
        title: "AI & Data Science.",
        description: "Empowering Businesses with Data-Driven Insights for Smarter Decisions."
    },
    {
        image: "/images/bg/devwea1.jpg",
        title: "SysOps & DevSecOps.",
        description: "Systems, Development, Security Operations to Empower your Business."
    },
    {
        image: "/images/bg/wea6.jpg",
        title: "Fullstack Development.",
        description: "Creating Seamless Digital Experiences Through Innovative Front & Backend Solutions."
    },
    {
        image: "/images/bg/weafeafinal.png",
        title: "Web Design.",
        description: "We Help our Clients Succeed by Creating Brand Identities, and Digital Experiences."
    },
]


const DigitalAgency = () => {
    return (
        <>
            <SEO title="Tech Services" />
            <main className="page-wrapper">
                
            <HeaderTopBar />                
            <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <Slider className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="height-950 bg-overlay bg_image" style={{backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`}}>
                                <div className="container">
                                    <div className="row row--30 align-items-center">
                                        <div className="order-2 order-lg-1 col-lg-7">
                                            <div className="inner text-start">
                                                <p className="title" dangerouslySetInnerHTML={{__html: data.title}}></p>
                                                <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                {/* End Slider Area  */}

                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Tech Services."
                                        description = "Neeed to write something cool in here, not particularly long, infact lets see how long we should put stuff in here."
                                     />
                                </div>
                            </div>
                            <ServiceTwo
                                cardStyle = "card-style-1"
                                textAlign = "text-start"
                             />
                        </div>
                    </div>
                    {/* End Service Area  */}

                
                 {/* Start About Area  */}
                 
                <AboutTwo /> 
                                            
                {/* End About Area  */}




                {/* esta es otra wea  */}


                                {/* Start Brand Area  */}
                <div className="rwt-brand-area pb--60 pt--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandOne brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}


                <FooterTwo />
                
            </main>
        </>
    )
}
export default DigitalAgency;