import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [

    {
        form: "Palo Alto, CA",
        description: "(...)[Greenvalley] worked quickly and thoroughly to solve a spreadsheet problem I'd been trying to solve for months. He made it so easy for me to move forward with the project after I was thinking it was impossible (...)",
        name: "Mary Russell",
        subtitle: "Stock Option Counselor",
        image: "Mary-Russell"
    },

    {
        form: "Gaithersburg, MD",
        description: "I needed a cost of waiting calculator for realestate. I got the project delivered, and best of all received help even after the project was done. We are very satisfied with the results.",
        name: "Prakash Karnani",
        subtitle: "EVP, Marketing & Technology",
        image: "homespire-logo"
    },
    {
        form: "Agoura Hills, CA",
        description: "We needed help outsourcing some projects and Green Valley adapted immediatly to our workflow. They started working with our in-house team to get the project done.",
        name: "Don A.",
        subtitle: "Founder & CEO",
        image: "testimonial-dark-02"
    },
]
const TestimonialThree = ({teamStyle}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <span className="form">{data.form}</span>
                                        <p className="description">{data.description}</p>
                                        <div className="client-info">
                                            <h4 className="title">{data.name}</h4>
                                            <h6 className="subtitle">{data.subtitle}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/testimonial/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default TestimonialThree;




















