import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: '/images/service/aiwea.jpg',
        title: 'AI & Data Science',
        description: 'Our advanced data science solutions deliver unparalleled insights, ensuring your business stays ahead.'
    },
    {
        image: '/images/service/weadev2.jpeg',
        title: 'SysOps & DevSecOps',
        description: 'Revolutionizing SysOps & DevSecOps to Keep Your Business at the Forefront, Maximizing Efficency.'
    },
    {
        image: '/images/service/weoneslocosnuevo.jpg',
        title: 'Fullstack Development',
        description: 'Delivering Cutting-Edge Fullstack Development Services with a Team of Top Tier Professionals.'
    },
    {
        image: '/images/service/cacaliquida.jpg',
        title: 'Web Design',
        description: 'Designing Immersive Web Experiences, Building Strong Brands, Uncompromising Attention to Detail.'
    }
]
const ServiceTwo = ({textAlign, cardStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`card-box ${cardStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <Link to="#service">
                                        <img src={`${val.image}`} alt="card Images" />
                                    </Link>
                                </div>
                                <div className="content">
                                    <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                    
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceTwo;