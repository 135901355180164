import React from 'react';
import {Link} from "react-router-dom";



const callToActionData = {
    title: "What Our Clients Say About Us.",
    subtitle: "Hear from the people who have experienced our services firsthand.",
    btnText: "View All Testimonials",
}
const CalltoActionFour = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-4">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper">
                    <div className="col-lg-8">
                        <div className="inner">
                            <div className="content text-start">
                                <h2 className="title">{callToActionData.title}</h2>
                                <h6 className="subtitle">{callToActionData.subtitle}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="call-to-btn text-start text-lg-right">
                        <Link to="/testimonial" className="btn-default">View All Testimonials</Link>

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionFour;

