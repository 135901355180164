import React from 'react';
import Typed from 'react-typed';

import {Link} from "react-router-dom";



const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/valle_rio_baker.jpg" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Unique <br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Business",
                                            "Finance",
                                            "Technology",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    /><br />
                                    Consulting.
                                </h2>
                                {/* <h6>Expertise Across Domains</h6>
                                <ul>
                                    <li><strong>Finance Consulting:</strong> Navigating financial landscapes to ensure stability and growth.</li>
                                    <li><strong>Business Consulting:</strong> Enhancing operational efficiency and strategic planning for enduring success.</li>
                                    <li><strong>Technology Consulting:</strong> Facilitating digital transformation, innovation, and gaining a competitive edge through technology.</li>
                                </ul> */}

                                <h6>Specializing in Outsourcing Projects</h6>
                                <p>Understanding the evolving needs of businesses, especially those seeking flexible workforce solutions, Green Valley Consulting offers outsourcing services. These services provide access to skilled professionals on demand, enabling companies to complete projects efficiently without the commitment of permanent hires.</p>

                                <h6>Why Choose Green Valley Consulting?</h6>
                                <ul>
                                    <li><strong>Integrated Solutions:</strong> Providing a holistic approach to address challenges across finance, business, and technology.</li>
                                    <li><strong>Flexibility & Scalability:</strong> Customized services to meet your specific needs, from short-term projects to long-term strategic planning.</li>
                                    <li><strong>Excellence in Execution:</strong> Committed to delivering exceptional outcomes through our team of experienced professionals.</li>
                                </ul>
                                <div className="read-more-btn mt--40">
                                    <Link to="/about-us" className="btn-default">About Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne
