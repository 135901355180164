import React from 'react';
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: '/images/service/weaitaoffice.jpg',
        title: 'Management Consulting',
        description: 'Elevate your organizational performance with our management consulting services, providing strategic insights and solutions to address your most critical challenges.'
    },
    {
        image: '/images/service/operations.jpeg',
        title: 'Operations Consulting',
        description: 'Optimize your business operations through our specialized consulting services, driving increased productivity, cost savings, and operational excellence.'
    },
    {
        image: '/images/service/trainchoice.webp',
        title: 'Strategy Consulting',
        description: "Achieve sustainable growth and competitive advantage through our strategic consulting services, offering deep insights and actionable strategies tailored to your business needs."
    }
]

const ServiceOne = ({ textAlign, serviceStyle }) => {
    return (
        <div className="row row--15 service-wrapper">
            {ServiceList.map((val, i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                    >
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="image-container">
                                <img src={val.image} alt={val.title} />
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{ __html: val.title }}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: val.description }}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;

