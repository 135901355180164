import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const TabOne = () => {
    return (
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30 align-items-center">
                                <div className="col-lg-5">
                                    <img className="radius-small" src='./images/tab/wealokamen.png' alt="Corporate React Template" />
                                </div>
                                <div className="col-lg-7 mt_md--40 mt_sm--40">
                                    <div className="rn-default-tab">
                                        <div className="tab-button-panel">
                                            <TabList className="tab-button">
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Management</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Operations</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Strategy</button>
                                                    </div>   
                                                </Tab>
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <h6>Leadership Assessment & Development:</h6>
                                                        <p>We conduct thorough assessments to understand your current leadership capabilities. Design personalized development programs aimed at enhancing leadership skills, decision-making abilities, and strategic thinking.</p>

                                                        <h6>Organizational Design:</h6>
                                                        <p>Our consultants work with you to design efficient organizational structures that support your business goals. Improve communication flows and foster a culture of accountability and innovation.</p>

                                                        <h6>Cultural Transformation:</h6>
                                                        <p>In today's competitive marketplace, cultural alignment is key. We guide you through the process of transforming your corporate culture to reflect your values. Enhance employee engagement and drive performance.</p>

                                                        <h6>Executive Coaching:</h6>
                                                        <p>For individual leaders, we offer executive coaching services. Provide one-on-one support, mentorship, and actionable feedback. Accelerate personal and professional growth.</p>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <h6>Process Optimization:</h6>
                                                        <p>We analyze your existing operations to pinpoint inefficiencies and recommend targeted improvements, leading to significant cost savings and increased throughput.</p>

                                                        <h6>Supply Chain Management:</h6>
                                                        <p>Our consultants work with you to design and implement robust supply chain strategies that ensure reliability, flexibility, and cost-effectiveness.</p>

                                                        <h6>Production Efficiency:</h6>
                                                        <p>By leveraging advanced analytics and lean manufacturing principles, we help you maximize resource utilization and minimize waste, driving higher quality and lower costs.</p>

                                                        <h6>Digital Transformation:</h6>
                                                        <p>In the digital age, staying ahead means embracing new technologies. We assist you in integrating digital solutions that enhance operational agility, visibility, and customer satisfaction.</p>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <h6>Market Analysis & Segmentation:</h6>
                                                        <p>We conduct comprehensive market analyses to understand trends, consumer behavior, and competitive dynamics, enabling you to make informed decisions about product development, pricing, and marketing strategies.</p>

                                                        <h6>Growth Strategy Development:</h6>
                                                        <p>Leveraging our expertise in both domestic and global markets, we help you identify and prioritize growth opportunities, whether through expansion, diversification, or innovation.</p>

                                                        <h6>Competitive Advantage Identification:</h6>
                                                        <p>Our strategic framework helps uncover your unique strengths and weaknesses relative to competitors, guiding the development of sustainable competitive advantages.</p>

                                                        <h6>Strategic Roadmapping:</h6>
                                                        <p>We create detailed roadmaps that outline the steps necessary to achieve your strategic objectives, including resource allocation, risk assessment, and milestone tracking.</p>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>
    )
}

export default TabOne
