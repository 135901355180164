import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="has-droupdown"><Link to="/">Home</Link>
            </li>
            
            <li className="has-droupdown"><Link to="/service">What We Do</Link>
            <ul className="submenu">
                    <li><Link to="/service">What We Do</Link></li>
                    <li><Link to="/business">Business Services</Link></li>
                    <li><Link to="/finance">Financial Services </Link></li>
                    <li><Link to="/digital-agency">Tech Services</Link></li>
                </ul>
            </li>


            <li className="has-droupdown"><Link to="/about-us">About Us</Link>
                <ul className="submenu">
                    <li><Link to="/about-us">About Us</Link></li>
                    <li><Link to="/testimonial">Testimonial</Link></li>
                    <li><Link to="/pricing">Pricing</Link></li>
                </ul>
            </li>


            {/* <li className="has-droupdown"><Link to="/blog-list-view">Blog</Link></li>  */}

            {/* <li className="has-droupdown"><Link to="/portfolio">Portfolio</Link></li> */}

            <li className="has-droupdown"><Link to="/contact">Contact</Link></li>
        </ul>
    )
}
export default Nav;
