import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import HeaderOne from "../../common/header/HeaderOne";
import FooterOne from "../../common/footer/FooterOne";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ServiceOne from "./ServiceOne";
import ServiceTwo from "./ServiceTwo";
import ServiceThree from "./ServiceThree";
import ServiceFour from "./ServiceFour";
import ServiceFive from "./ServiceFive";
import Separator from "../separator/Separator";

const Service = () => {
    return (
        <>

            <SEO title="What We Do" />
            <Layout>
                <BreadcrumbOne 
                    title="Transforming Challenges Into Opportunities </br> -Our Services."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Service"
                />
                <div className="main-content">

                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Empowering Growth with Expertise and Innovation"
                                        title = "Business Services."
                                        description = "Enhancing operational efficiency and fostering sustainable growth through innovative approaches and deep industry knowledge."
                                     />
                                </div>
                            </div>
                            <ServiceOne 
                                serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                                textAlign = "text-center"
                            />
                        </div>
                    </div>
                    {/* End Service Area  */}



                    <Separator />

                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Financial Strategies for Tomorrow's Success"
                                        title = "Financial Services."
                                        description = "Maximize your financial potential with our expert guidance, tailored to help you navigate market fluctuations and achieve long-term stability and growth."
                                     />
                                </div>
                            </div>
                            <ServiceThree 
                                serviceStyle = "service__style--2"
                                textAlign = "text-center"
                             />
                        </div>
                    </div>
                    {/* End Service Area  */}


                    <Separator />


                    
                    <Separator />


                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Innovative Solutions for Digital Transformation"
                                        title = "Tech Services."
                                        description = "Harnessing technology to drive business success, our tech services provide cutting-edge solutions for today's digital challenges."
                                     />
                                </div>
                            </div>
                            <ServiceTwo
                                cardStyle = "card-style-1"
                                textAlign = "text-start"
                             />
                        </div>
                    </div>
                    {/* End Service Area  */}


                    
                </div>

            </Layout>
            
        </>
    )
}

export default Service;
