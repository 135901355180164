import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: '/images/service/portfoliomanagement.png',
        title: 'Portfolio Management',
        description: 'Maximize your investment returns and minimize risk with our comprehensive portfolio management services, designed to align with your financial objectives and risk tolerance.'
    },
    {
        image: '/images/service/mergerandaquisition.png',
        title: 'M & A',
        description: 'Navigate the complexities of mergers and acquisitions with confidence, leveraging our expert guidance to optimize deal structures and achieve successful outcomes.'
    },
    {
        image: '/images/service/ampolleta.png',
        title: 'Fractional CFO',
        description: "Leverage our part-time CFO expertise to gain strategic financial insights and guidance, tailored to fit your business's unique needs and goals."
    }
]
const ServiceThree = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <img src={`${val.image}`} alt="card Images" />
                                </div>
                                <div className="content">
                                    <h4 className="title"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceThree;