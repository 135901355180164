import React from 'react';
import { FiPhone, FiMapPin, FiFile ,FiMail} from "react-icons/fi";


const AboutFive = () => {
    return (
        <div className="about-style-5 rn-section-gapBottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="row row--0 about-wrapper align-items-center theme-shape">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img src="./images/about/fotopie.jpeg" alt="About Images" />
                                </div>
                            </div>
                            <div className="col-lg-6 mt_md--30 mt_sm--30">
                                <div className="content">
                                    <div className="inner">
                                        <h4 className="title">Pricing Information</h4>
                                        <p>In light of the extensive diversity of our services, we prioritize ensuring unparalleled client satisfaction above all else. Consequently, providing an accurate pricing estimate requires a comprehensive discussion of your specific project or concept with our seasoned professionals. This collaborative approach allows us to tailor our solutions precisely to meet your needs, ensuring the highest quality outcome.</p>
                                        <ul className="contact-address">
                                            <li><FiFile /> Green Valley Consulting</li>
                                            <li><FiPhone /> <a href="tel:+13055042289">+1 (305) 504 2289</a></li>
                                            <li><FiPhone /> <a href="tel:+17866254409">+1 (786) 625 4409</a></li>
                                            <li><FiMapPin /> 78 SW 7th St, Miami, FL 33130</li>
                                            <li><FiMail /> contact@greenvalley.solutions </li>
                                        </ul>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AboutFive;